<template>
  <div>
    <section class="bg-home d-flex align-items-center mb-5" style="height: auto;">
      <div class="container">
        <div class="row mt-5 justify-content-center">
          <div class="col-lg-8 col-md-12 text-center">
            <img src="/images/404.svg" class="img-fluid" alt="">
            <div class="text-uppercase mt-4 display-3">Oh :(</div>
            <div class="text-capitalize text-dark mb-4 error-page">Não encontramos a página</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <button @click="toBack()" class="btn btn-outline-primary mt-4">Voltar</button>
            <router-link to="/" class="btn btn-primary mt-4 ml-2">Ir para o início</router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  },
  methods: {
    toBack() {
      window.history.back()
    },
  }
}
</script>
